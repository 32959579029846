export const BREAKPOINTS = {
  mobile: 540,
  mobileLandscape: 812,
  tablet: 1280,
}

export const FONT_WEIGHT = {
  thin: 100,
  light: 200,
  demilight: 300,
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
}

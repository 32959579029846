import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'

import { mq, rem } from '../../utils/styles'

const Container = styled.nav`
  font-size: ${rem(15)};
  font-weight: 500;
  letter-spacing: 0.025rem;
  position: absolute;
  right: -10px;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 10;

  ${mq.lessThan('mobileLandscape')} {
    right: -7px;
  }

  ${mq.lessThan('mobile')} {
    background: #f5f5f5;
    font-size: ${rem(19)};
    height: 100%;
    margin-top: 80px;
    opacity: 0;
    position: fixed;
    right: 0;
    visibility: hidden;
    width: 100%;

    @media (prefers-color-scheme: dark) {
      background: #333;
    }
  }

  .list {
    display: flex;
    list-style: none;

    ${mq.lessThan('mobile')} {
      display: block;
      margin-top: 100px;
      text-align: center;
    }
  }

  .item {
    margin-left: 18px;

    ${mq.lessThan('mobileLandscape')} {
      margin-left: 7px;
    }

    ${mq.lessThan('mobile')} {
      margin-left: 0;
    }

    a {
      padding: 10px;
      position: relative;

      ${mq.lessThan('mobileLandscape')} {
        padding: 10px 7px;
      }

      ${mq.lessThan('mobile')} {
        display: inline-block;
      }

      &::after {
        background: #333;
        bottom: 0;
        content: '';
        height: 1px;
        left: 0;
        margin: auto;
        position: absolute;
        right: 0;
        top: 0;
        transform: scale(0, 1);
        transform-origin: 100% 50% 0;
        transition: transform 0.45s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
        width: 100%;

        @media (prefers-color-scheme: dark) {
          background: #f5f5f5;
        }
      }

      &:hover {
        &::after {
          transform: scale(1, 1);
          transform-origin: 0 50% 0;
          transition: transform 0.65s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
        }
      }

      &.is-active {
        &::after {
          transform: scale(1, 1);
          transition: transform 0s;
        }
      }

      &.is-close {
        color: #999;
        pointer-events: none;

        &:hover {
          content: none;
        }
      }
    }
  }
`

type NavProps = {
  menuLinks: {
    name: string
    link: string
  }[]
}

const Nav: React.FC<NavProps> = ({ menuLinks }: NavProps) => {
  return (
    <Container className="nav">
      <ul className="list">
        {menuLinks.map(link => (
          <li className="item" key={link.link}>
            <Link
              to={link.link}
              activeClassName="is-active"
              partiallyActive={true}
            >
              {link.name}
            </Link>
          </li>
        ))}
      </ul>
    </Container>
  )
}

export default Nav

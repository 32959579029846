export const isBrowser = () => typeof window !== 'undefined'

const MOBILE_BREAKPOINT = '33.75em'

export function getMobileMediaQuery() {
  const mobileMql = window.matchMedia(`(max-width: ${MOBILE_BREAKPOINT})`)
  return mobileMql
}

export function isMobileScreen() {
  const mobileMql = getMobileMediaQuery()
  return mobileMql.matches
}

import { Link } from 'gatsby'
import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'

import { getMobileMediaQuery } from '../../utils/utils'
import Title from '../../images/title.svg'
import Nav from './Nav'
import { mq } from '../../utils/styles'

const Container = styled.header`
  padding: 0 70px;

  ${mq.lessThan('mobileLandscape')} {
    padding: 0 6vw;
  }

  ${mq.lessThan('mobile')} {
    padding: 0 6vw;
  }

  &.is-navOpen {
    .nav {
      ${mq.lessThan('mobile')} {
        opacity: 1;
        visibility: visible;
      }
    }

    .hamburger {
      transform: rotate(45deg);

      &::before {
        ${mq.lessThan('mobile')} {
          opacity: 0;
          visibility: visible;
        }
      }

      &::after {
        ${mq.lessThan('mobile')} {
          bottom: 0;
          transform: rotate(-90deg);
        }
      }
    }
  }

  .content {
    height: 80px;
    position: relative;
  }

  .title {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);

    @media (prefers-color-scheme: dark) {
      filter: invert(100);
    }

    a {
      display: block;
      width: 175px;

      ${mq.lessThan('mobile')} {
        width: 143px;
      }

      img {
        display: block;
      }
    }
  }

  .menuBtn {
    display: none;

    ${mq.lessThan('mobile')} {
      cursor: pointer;
      display: block;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(0, -50%);
      width: 23px;
    }

    &::before {
      bottom: 0;
      content: '';
      display: block;
      height: 40px;
      left: 50%;
      margin: auto;
      position: absolute;
      top: 0;
      transform: translate(-50%, 0);
      width: 40px;
    }
  }

  .hamburger {
    ${mq.lessThan('mobile')} {
      background: #333;
      height: 1px;
      width: 100%;
      z-index: 100;

      @media (prefers-color-scheme: dark) {
        background: #f5f5f5;
      }
    }

    &::before {
      ${mq.lessThan('mobile')} {
        background: #333;
        content: '';
        height: 1px;
        position: absolute;
        top: -8px;
        width: 100%;

        @media (prefers-color-scheme: dark) {
          background: #f5f5f5;
        }
      }
    }

    &::after {
      ${mq.lessThan('mobile')} {
        background: #333;
        bottom: -8px;
        content: '';
        height: 1px;
        position: absolute;
        width: 100%;

        @media (prefers-color-scheme: dark) {
          background: #f5f5f5;
        }
      }
    }
  }
`

const Header = props => {
  const [isNavOpen, setIsNavOpen] = useState(false)

  const handleClickMenuBtn = () => {
    setIsNavOpen(!isNavOpen)
  }

  useEffect(() => {
    const mql = getMobileMediaQuery()
    const handleMediaChange = e => {
      if (e.matches) return
      setIsNavOpen(false)
    }

    mql.addListener(handleMediaChange)

    return () => mql.removeListener(handleMediaChange)
  }, [])

  return (
    <Container className={`${isNavOpen ? 'is-navOpen' : ''}`}>
      <div className="content">
        <h1 className="title">
          <Link to="/">
            <img src={Title} alt="YUSUKE IYONAGA" />
          </Link>
        </h1>
        <Nav {...props} />
        <div className="menuBtn" onClick={handleClickMenuBtn}>
          <div className="hamburger" />
        </div>
      </div>
    </Container>
  )
}

export default Header

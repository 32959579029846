/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from '@emotion/styled'
import 'typeface-libre-franklin'

import Header from '../Header'
import { mq } from '../../utils/styles'

const Container = styled.main`
  padding: 0 70px;

  ${mq.lessThan('mobileLandscape')} {
    padding: 0 6vw;
  }

  ${mq.lessThan('mobile')} {
    padding: 0 6vw;
  }

  .column {
    margin: 0 auto;
    max-width: 1080px;
  }
`

type LayoutProps = {
  children?: React.ReactNode
  layout?: string
  data: {
    site: {
      siteMetadata: {
        menuLinks: {
          name: string
          link: string
        }[]
      }
    }
  }
}

// const Layout: React.FC<LayoutProps> = ({ children, layout }: LayoutProps) => (
//   <StaticQuery
//     query={graphql`
//       query SiteTitleQuery {
//         site {
//           siteMetadata {
//             title
//             menuLinks {
//               name
//               link
//             }
//           }
//         }
//       }
//     `}
//     render={data => (
//       <>
//         <Header menuLinks={data.site.siteMetadata.menuLinks} />
//         <Container>
//           {layout === 'home' ? (
//             children
//           ) : (
//             <div className="column">{children}</div>
//           )}
//         </Container>
//       </>
//     )}
//   />
// )

export const PureLayout: React.FC<LayoutProps> = ({
  layout,
  children,
  data,
}: LayoutProps) => (
  <>
    <Header menuLinks={data.site.siteMetadata.menuLinks} />
    <Container>
      {layout === 'home' ? children : <div className="column">{children}</div>}
    </Container>
  </>
)

const Layout = props => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            menuLinks {
              name
              link
            }
          }
        }
      }
    `}
    render={data => <PureLayout {...props} data={data} />}
  />
)

export default Layout

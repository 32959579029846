import { BREAKPOINTS } from './constants'

export const em = (value: number, ratio = 16) => {
  return `${value / ratio}em`
}

export const rem = (value: number, ratio = 10) => {
  return `${value / ratio}rem`
}

const getSizeFromBreakpoint = (value: string | number) => {
  if (typeof value === 'number') {
    return value
  }

  if (BREAKPOINTS[value]) {
    return BREAKPOINTS[value]
  }

  throw new Error(`No valid breakpoint or size specified for media.`)
}

export const mq = {
  lessThan: (value: number | string) => {
    const maxWidth = getSizeFromBreakpoint(value)
    return `@media (max-width: ${em(maxWidth)})`
  },

  greaterThan: (value: number | string) => {
    const minWidth = getSizeFromBreakpoint(value)
    return `@media (min-width: ${em(minWidth + 1)})`
  },

  between: (from: number | string, until: number | string) => {
    const minWidth = getSizeFromBreakpoint(from)
    const maxWidth = getSizeFromBreakpoint(until)
    return `@media (min-width: ${em(minWidth + 1)}) and (max-width: ${em(
      maxWidth
    )})`
  },
}
